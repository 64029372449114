import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  doc,
  deleteDoc,
  orderBy,
  query,
} from "firebase/firestore";
import React, { useState, useEffect, Fragment } from "react";
import { db } from "../utils/firebase";
import { styled } from "@mui/material/styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Layout from "./Layout";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const servicesCollection = collection(db, "services");
const sortedDocs = query(servicesCollection, orderBy("createdAt", "asc"));

const Demo = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

function Edit({ handleSignOut }) {
  const [products, setProducts] = useState([]);
  const [open, setOpen] = useState(false);
  const [category, setCategory] = useState("");
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [time, setTime] = useState("");
  const [activeProduct, setActiveProduct] = useState("");
  const [productToDelete, setProductToDelete] = useState("");

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setActiveProduct("");
    resetFields();
  };

  const closeDeleteModal = () => {
    setProductToDelete("");
  };

  const handleChange = (event) => {
    setCategory(event.target.value);
  };

  const fetchProducts = () => {
    getDocs(sortedDocs)
      .then((data) =>
        setProducts(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
      )
      .catch(() => console.error("No permissions!"));
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const resetFields = () => {
    setName("");
    setPrice("");
    setTime("");
    setCategory("");
  };

  const createProduct = () => {
    const data = {
      name,
      price: Number(price),
      time,
      category,
      createdAt: Date.now(),
    };

    addDoc(servicesCollection, data).then(() => fetchProducts());
  };

  const updateProduct = (id) => {
    const data = {
      name,
      price: Number(price),
      time,
      category,
    };
    const prodDoc = doc(db, "services", id);
    updateDoc(prodDoc, data).then(() => fetchProducts());
  };

  const handleEdit = (prod) => {
    const { name, price, time, category, id } = prod;
    setName(name);
    setPrice(price);
    setTime(time);
    setCategory(category);
    setActiveProduct(id);
    handleOpen();
  };

  const onSubmit = () => {
    if (activeProduct) {
      updateProduct(activeProduct);
    } else {
      createProduct();
    }
    handleClose();
  };

  const deleteProduct = () => {
    const prodDoc = doc(db, "services", productToDelete);
    deleteDoc(prodDoc).then(() => fetchProducts());
    closeDeleteModal();
  };

  return (
    <Layout>
      <div className="editPage">
        <button onClick={handleSignOut}>Sign Out</button>
        <Grid item xs={12} md={6}>
          <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
            Izmeni cenovnik
          </Typography>
          <Demo>
            <List dense>
              {products.map((item) => (
                <Fragment key={item.id}>
                  <ListItem
                    secondaryAction={
                      <>
                        <IconButton
                          edge="end"
                          className="icon"
                          onClick={() => handleEdit(item)}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          edge="end"
                          className="icon"
                          onClick={() => setProductToDelete(item.id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </>
                    }
                  >
                    <ListItemText primary={`${item.name} - ${item.price}`} />
                  </ListItem>
                  <Divider />
                </Fragment>
              ))}
            </List>
          </Demo>
        </Grid>
        <Button variant="contained" onClick={handleOpen}>
          Dodaj
        </Button>
        <Modal open={open} onClose={handleClose}>
          <Box sx={style}>
            <Typography variant="h6" component="h2">
              {Boolean(activeProduct) ? "Izmeni uslugu" : "Dodaj novu uslugu"}
            </Typography>
            <Box>
              <TextField
                fullWidth
                label="Naziv"
                variant="outlined"
                margin="normal"
                size="small"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <TextField
                fullWidth
                label="Cena"
                variant="outlined"
                margin="normal"
                size="small"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />
              <TextField
                fullWidth
                label="Trajanje"
                variant="outlined"
                margin="normal"
                size="small"
                value={time}
                onChange={(e) => setTime(e.target.value)}
              />
              <FormControl fullWidth margin="normal">
                <InputLabel size="small">Kategorija</InputLabel>
                <Select
                  value={category}
                  label="Category"
                  size="small"
                  onChange={handleChange}
                >
                  <MenuItem value={"nutricionista"}>
                    Nutricionista - Dijetetičar
                  </MenuItem>
                  <MenuItem value={"masaze"}>Masaže</MenuItem>
                  <MenuItem value={"lipoliza"}>
                    Tretmani ultrazvučne lipolize
                  </MenuItem>
                  <MenuItem value={"kavitacija"}>Kavitacija</MenuItem>
                  <MenuItem value={"ostalo"}>Ostalo</MenuItem>
                </Select>
              </FormControl>
              <br />
              <Button
                disabled={!name || !price || !category}
                variant="contained"
                onClick={onSubmit}
              >
                {Boolean(activeProduct) ? "Izmeni" : "Dodaj"}
              </Button>
              <Button onClick={handleClose}>Odustani</Button>
            </Box>
          </Box>
        </Modal>
        <Modal open={Boolean(productToDelete)} onClose={closeDeleteModal}>
          <Box sx={style}>
            <Typography variant="h6" component="h2">
              Obrisi uslugu?
            </Typography>
            <br />
            <Button variant="contained" onClick={deleteProduct}>
              Obrisi
            </Button>
            <Button onClick={closeDeleteModal}>Odustani</Button>
          </Box>
        </Modal>
      </div>
    </Layout>
  );
}

export default Edit;
